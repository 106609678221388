import { isAxiosError, mostSpecificCause } from '@mentimeter/errors';
import type { Event, EventHint } from '@sentry/nextjs';

export function dashboardErrorMiddleware(
  event: Event,
  hint: EventHint,
  originalException: Error,
): Event | null {
  const specificError = mostSpecificCause(originalException);

  if (isAxiosError(specificError)) {
    return null;
  }

  return event;
}

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a262914a1b41389a3a8f1185e14bbaa57972cf9e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
  errorMiddleware,
} from '@mentimeter/sentry-config';
import { dashboardErrorMiddleware } from './sentry-error-middleware';

const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev';
const shouldHavePerformanceMonitor = environmentName === 'prod';

Sentry.init({
  dsn: 'https://2a6285d329947beb0b3109b0f528b251@o866780.ingest.sentry.io/4506575158706176',
  ...standardSentryConfig,
  ...extraClientConfig,
  beforeSend: errorMiddleware(dashboardErrorMiddleware),
  tracesSampleRate: shouldHavePerformanceMonitor ? 0.001 : 0,
  // This MUST be here as overwrite in the client config for env to be set with next-env-vars
  // Can be removed when this application is cf pages only
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev',
});
